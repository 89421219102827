<template>
  <div class="chart_top">
    <div class="item">
      <countEchart ref="countEchart" />
      <div class="right_box">
        <p>
          本月应续费客户<span>{{ customerCount }}</span
          >户
        </p>
        <p>
          本月应续费金额<span>{{ countNumber }}</span
          >元
        </p>
        <p>
          欠费客户数量<span>{{ arrearsCount }}</span
          >户
        </p>
        <p>
          欠费金额<span>{{ arrearsNumber }}</span
          >元
        </p>
        <p>
          已缴费金额<span>{{ countActualNumber }}</span
          >元
        </p>
        <p>
          未补齐尾款客户数量<span>{{ complementCount }}</span
          >户
        </p>
        <p>
          未补齐尾款金额<span>{{ complementNumber }}</span
          >元
        </p>
      </div>
    </div>
    <div class="item">
      <customerEchart ref="customerEchart" />
      <div class="right_box">
        <p>总跟进客户数<span>{{totalTrack}}</span>户</p>
        <p>本月新增跟进客户数<span>{{monthTrack}}</span>户</p>
        <p>签约新客户数量<span>{{monthCooperate}}</span>户</p>
      </div>
    </div>
    <!-- <div class="item">
      <businessEchart/>
      <div class="right_box">
        <p>全部业务<span>0</span>项</p>
        <p>在办业务<span>0</span>项</p>
        <p>超期业务<span>0</span>项</p>
      </div>
    </div> -->
  </div>
  <div class="bottom">
    <receiveEchart  ref="receiveEchart"/>
  </div>
</template>

<script>
import countEchart from "./components/countEchart.vue";
import businessEchart from "./components/businessEchart.vue";
import customerEchart from "./components/customerEchart.vue";
import receiveEchart from "./components/receiveEchart.vue";
import { homeList } from "../../../api/crm";
export default {
  name: "crmIndex",
  components: {
    countEchart,
    businessEchart,
    customerEchart,
    receiveEchart,
  },
  data() {
    return {
      customerCount: 0, //本月应续费客户
      countNumber: 0, //本月应续费金额
      countActualNumber: 0, //已缴费金额
      totalNumber: 0, //总计金额
      arrearsCount: 0, //本月欠费客户
      complementCount: 0, //未补齐尾款客户数量
      arrearsNumber: 0, //本月欠费金额
      complementNumber: 0, //未补齐尾款
      totalTrack:0,//总跟进客户
      monthTrack:0,//本月新增跟进客户
      monthCooperate:0,//本月新增合作客户
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      homeList({}).then(res=>{
        if(res.data.msg == 'success'){
          this.customerCount = res.data.data.list.customerCount,
          this.arrearsNumber = res.data.data.list.arrearsNumber,
          this.countNumber = res.data.data.list.countNumber,
          this.countActualNumber = res.data.data.list.countActualNumber,
          this.totalNumber = res.data.data.list.totalNumber,
          this.arrearsCount = res.data.data.list.arrearsCount,
          this.complementCount = res.data.data.list.complementCount,
          this.complementNumber = res.data.data.list.complementNumber,
          this.monthTrack = res.data.data.list.monthTrack,
          this.totalTrack = res.data.data.list.totalTrack,
          this.monthCooperate = res.data.data.list.monthCooperate,
          this.$refs.countEchart.init(res.data.data.countList),
          this.$refs.customerEchart.init(res.data.data.customersList),
          this.$refs.receiveEchart.init(res.data.data.datas.count)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_top {
  width: 99%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-top: 16px;
}
.item {
  width: 40%;
  height: 262px;
  border: 1px solid #c2c2c2;
  padding: 20px 10px;
  position: relative;
}

.right_box {
  width: 40%;
  position: absolute;
  right: 10px;
}
.right_box p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  line-height: 25px;
  font-weight: 400;
}
.right_box p span {
  margin: 0 6px;
  font-weight: 600 !important;
}
.bottom {
  width: 99%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>