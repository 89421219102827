<template>
  <div id="main"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "receiveEchart",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list) {
      let data = list.map(v=>{
        return  v*1
      })
      var myChart = echarts.init(document.getElementById("main"));
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "回款数",
            type: "bar",
            barWidth: "30%",
            data: data,
          },
        ],
      });
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  height: 350px;
}
</style>
