<template>
  <div id="count" style="width: 70%; height: 220px; float: left"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "countEchart",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    
  },
  methods: {
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list) {
      let arrearsNumber = list.arrearsNumber
      let countActualNumber = list.countActualNumber
      let countNumber = list.countNumber
      var myChart = echarts.init(document.getElementById("count"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        title: {
          text: "本月回款",
          top: "bottom",
          left: "center",
          textStyle: {
            fontSize: "14",
            color: "#409eff",
            fontWeight: 400,
          },
        },
        legend: {
          orient: "vertical",
          x: "left",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            label: {
              show: false,
              position: "center",
              formatter: "{b}:{c}" + "\n\r" + "({d}%)",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "13",
                fontWeight: "bold",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              { value: countNumber, name: "应续费金额" },
              { value: arrearsNumber, name: "欠费金额" },
              { value: countActualNumber, name: "已缴费金额" },
            ],
          },
        ],
      });
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="less">
</style>
