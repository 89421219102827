<template>
  <div id="business" style="width: 70%; height: 220px; float: left"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "businessEchart",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      var myChart = echarts.init(document.getElementById("business"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          x: "left",
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            label: {
              show: false,
              position: "center",
              // formatter: '{b}:{c}' + '\n\r' + '({d}%)',
              //  normal:{},
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              { value: 1048, name: "在办业务" },
              { value: 735, name: "超期业务" },
            ],
          },
        ],
      });
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="less">
</style>
