<template>
  <div id="customer" style="width: 70%; height: 220px; float: left"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "customerEchart",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
   
  },
  methods: {
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list) {
      let monthTrack = list.monthTrack
      let monthCooperate = list.monthCooperate
      var myChart = echarts.init(document.getElementById("customer"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        title: {
          text: "本月新增",
          top: "bottom",
          left: "center",
          textStyle: {
            fontSize: "14",
            color: "#409eff",
            fontWeight: 400,
          },
        },
        legend: {
          orient: "vertical",
          x: "left",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            label: {
              show: false,
              position: "center",
              formatter: "{b}:{c}" + "\n\r" + "({d}%)",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "13",
                fontWeight: "bold",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            labelLine: {
              show: true,
            },
            data: [
              { value: monthTrack, name: "本月新增跟进客户数" },
              { value: monthCooperate, name: "签约新客户数" },
            ],
          },
        ],
      });
      window.onresize = function () {
        myChart.resize();
      };
    },
  },
};
</script>

<style scoped lang="less">
</style>
